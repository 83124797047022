





































import { Component, Vue } from 'vue-property-decorator';
import { STORY_TEMPLATES } from '@/helpers/templateHelper';
import { prepareVariablesForSingleEntityQuery, redirectToSingleEntityRoute } from '@/helpers/graphqlHelper';
import { formatDate } from '@/helpers/dateTimeHelper';

import storyQuery from '@/graphql/story/Story.single.query.gql';
import addToFavoritesMutation from '@/graphql/me/favorite/AddToUserFavorites.mutation.gql';
import deleteFromUserFavoritesMutation from '@/graphql/me/favorite/DeleteFromUserFavorites.mutation.gql';

import AddToFavoriteAction from '@/components/partials/actions/AddToFavoriteAction.vue';
import AddToGuideAction from '@/components/partials/actions/AddToGuideAction.vue';
import MsgEntityNotFound from '@/components/MsgEntityNotFound.vue';

@Component({
  components: {
    MsgEntityNotFound,
    AddToGuideAction,
    AddToFavoriteAction,
    StoryDefaultTemplate: () => import('@/components/partials/StoryDefaultTemplate.vue'),
    StoryCampaignGridTemplate: () => import('@/components/partials/StoryCampaignGridTemplate.vue'),
    StoryCampaignGroupsTemplate: () => import('@/components/partials/StoryCampaignGroupsTemplate.vue'),
    AppRelatedSection: () => import('@/components/partials/AppRelatedSection.vue'),
  },
  metaInfo() {
    return {
      title: this.story?.title || 'Story',
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.story = null;
    this.storyExists = false;
    this.isFavoriteState = false;
    this.entityDataFetching = true;
    next();
  },
  apollo: {
    story: {
      query: storyQuery,
      variables() {
        return this.queryVariables;
      },
      result() {
        if (this.queryVariables.onlyId && this.story?.id) {
          this.$router.replace(
            redirectToSingleEntityRoute(this.$route.name, this.story.id, this.queryVariables.slug, {
              query: this.$route.query,
            })
          );
          return;
        }

        this.entityDataFetching = false;

        if (this.story?.id) {
          this.storyExists = true;
          this.isFavoriteState = this.story.is_favorite_by_current_user;
        }
      },
    },
  },
})
export default class StorySinglePage extends Vue {
  availableTemplates: any = STORY_TEMPLATES;
  entityDataFetching = true;
  isFavoriteState = false;
  story: any = null;
  storyExists = false;

  get queryVariables() {
    return prepareVariablesForSingleEntityQuery(this.$route.params);
  }

  get isCampaignGridItemsTemplate() {
    return this.story?.template === this.availableTemplates.campaignGridItems;
  }

  get isCampaignGroupsTemplate() {
    return this.story?.template === this.availableTemplates.campaignGroups;
  }

  get media(): string {
    return this.story.show_media_cover ? this.story.media?.[0]?.url : '';
  }

  get mediaCreditLine() {
    return this.story.mainMediaDetails ? this.story.mainMediaDetails.creditLine : null;
  }

  get publishedAt() {
    return formatDate(this.story.startPublishingAt);
  }

  handleFavoriteAction() {
    if (!this.isFavoriteState) {
      this.$apollo
        .mutate({
          mutation: addToFavoritesMutation,
          variables: {
            entities: [{ id: this.story.id, type: this.story.__typename }],
          },
        })
        .then((result) => {
          this.isFavoriteState = result.data.addToUserFavorites;
        });
      return;
    }

    this.$apollo
      .mutate({
        mutation: deleteFromUserFavoritesMutation,
        variables: {
          entities: [{ id: this.story.id, type: this.story.__typename }],
        },
      })
      .then((result) => {
        this.isFavoriteState = !result.data.deleteFromUserFavorites;
      });
  }
}
