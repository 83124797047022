import { render, staticRenderFns } from "./StorySinglePage.vue?vue&type=template&id=40b4e552&scoped=true&"
import script from "./StorySinglePage.vue?vue&type=script&lang=ts&"
export * from "./StorySinglePage.vue?vue&type=script&lang=ts&"
import style0 from "./StorySinglePage.vue?vue&type=style&index=0&id=40b4e552&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40b4e552",
  null
  
)

export default component.exports